@import './global.scss';
.sD {
  @include mob {
    display: none;
  }
}
.sM {
  display: none;
  @include mob {
    display: block;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: $d1368px;
  @include mob {
    max-width: $m340px;
  }
}
header {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  height: $d80px;
  @include mob {
    height: $m54px;
  }
  .raw {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include mob {
      grid-template-columns: 1fr;
    }
    gap: 0;
    .LC {
      padding-top: $d41px;
      @include mob {
        padding-top: $m31px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .logo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $d9px;
        @include mob {
          gap: 0;
        }
        cursor: pointer;
        .logoDot {
          background-image: url(../images/logoCircle.svg);
          background-position: center center;
          background-size: 100%;
          background-repeat: no-repeat;
          width: $d14px;
          height: $d14px;
          margin-top: $d6px;
          &.white {
            background-image: url(../images/logoCircleW.svg);
          }
          @include mob {
            width: $m11px;
            height: $m11px;
            margin-top: $m3px;
          }
          -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
        }
        .logoText {
          font-style: normal;
          font-weight: 600;
          font-size: $d30px;
          line-height: $d36px;
          @include mob {
            font-size: $m21px;
            line-height: $m21px;
            padding-left: $m6px;
          }
          letter-spacing: 0.015em;
          text-transform: uppercase;
          color: #000000;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    .RC {
      display: flex;
      justify-content: flex-end;
      padding-top: $d36px;
      @include mob {
        display: none;
      }
    }
  }
}
.mobB {
  text-align: center;
  display: none;
  @include mob {
    display: block;
  }
}
.mailButton {
  display: inline-block;
  height: $d46px;
  border: 0.10416666667vw solid #ff3d22;
  border-radius: $d100px;
  font-style: normal;
  font-weight: 600;
  font-size: $d17px;
  line-height: $d46px;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
  padding: 0 $d40px;
  cursor: pointer;
  &:hover {
    color: #000000;
    text-decoration: none;
    background: #ff3d22;
    color: #ffffff;
  }
  @include mob {
    height: $m46px;
    border-radius: $m100px;
    font-size: $m14px;
    line-height: $m45px;
    padding: 0 $m40px;
    border: 2px solid #ff3d22;
  }
}
body.showreal .mailButton {
  @include mob {
    border: 2px solid #ffffff;
  }
}
.button {
  display: inline-block;
  height: $d49px;
  border: 0.10416666667vw solid #000000;
  border-radius: $d100px;
  font-style: normal;
  font-weight: 600;
  font-size: $d16px;
  line-height: $d49px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 0 $d50px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background: #000000;
    color: #ffffff;
  }
  @include mob {
    font-size: $m16px;
    line-height: $m49px;
    height: $m49px;
    padding: 0 $m50px;
    border: 2px solid #000000;
  }
}
footer {
  padding: $d66px 0;
  background: #ff3d22;
  text-align: center;
  @include mob {
    padding: $m34px 0 $m42px;
  }
  .raw {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    align-items: flex-start;
    justify-content: center;
    @include mob {
      grid-template-columns: 1fr;
      gap: $m19px;
    }
  }
  .lets {
    display: block;
    width: $d573px;
    height: $d132px;
    background-image: url(../images/lets.svg);
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m340px;
      height: $m75px;
      background-image: url(../images/letsM.svg);
    }
  }
  .mailButton {
    display: block;
    border: $d4px solid #ffffff;
    height: $d100px;
    font-size: $d40px;
    line-height: $d100px;
    color: #000000;
    @include mob {
      display: inline-block;
      border: 2px solid #ffffff;
      font-size: $m17px;
      height: $m46px;
      line-height: $m46px;
      padding: 0 $m50px;
    }
    &:hover {
      color: #000000;
      text-decoration: none;
      background: #ffffff;
    }
  }
}
section.home {
  background: #e8dfdf;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  @include desk {
    align-items: center;
    justify-content: center;
  }
  @include mob {
    width: 100vw;
    overflow: hidden;
    align-items: flex-end;
    flex-direction: column-reverse;
    // background-image: url(../images/m1.png);
    // background-position: bottom center;
    // background-size: 100%;
    // background-repeat: no-repeat;
  }
  .noise__container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;
    .noise {
      -webkit-animation: grain 7s steps(10) infinite;
      -o-animation: grain 7s steps(10) infinite;
      animation: grain 7s steps(10) infinite;
      background-image: url(../images/noise.png);
      height: 285%;
      left: -50%;
      opacity: 0.4;
      position: absolute;
      top: -100%;
      width: 300%;
    }
  }
  .pBgs {
    position: static !important;
  }
  .photoBg {
    position: absolute;
    top: -$d296px;
    right: $d136px;
    left: $d136px;
    height: $d615px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @include transition(all 0.3s ease-in-out);
    opacity: 0;
    @include mob {
      position: fixed !important;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: auto;
    }
    video {
      width: 100%;
    }
  }
  .photoBg1 {
    background-image: url(../images/i1bg.jpg);
  }
  .middle {
    position: relative;
    width: 100%;
    min-width: 100%;
    @include mob {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      flex-grow: 1;
    }
    & > * {
      @include desk {
        position: absolute;
      }
    }
    .center {
      position: absolute;
      left: 50%;
      @include mob {
        position: relative;
        flex-grow: 1;
        order: 10;
        width: 100%;
        left: 0;
        // justify-self: flex-end;
        padding-top: $m54px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > * {
        position: absolute;
      }
    }
  }
  .menu {
    @include desk {
      top: -$h198px;
    }
    width: 100%;
    @include mob {
      order: 9;
      // top: auto;
      // bottom: $m116px;
      @include transition(all 0.3s ease-in-out);
    }
    .menuItems {
      @include desk {
        display: grid;
        height: $h456px;
        grid-template-columns: 1fr 1fr;
        row-gap: $h100px;
        .item1 {
          grid-row-start: 1;
        }
        .item2 {
          grid-row-start: 2;
        }
        .item3 {
          grid-row-start: 3;
        }
        .item4 {
          grid-row-start: 1;
        }
        .item5 {
          grid-row-start: 2;
        }
        .item6 {
          grid-row-start: 3;
        }
        .item4,
        .item5,
        .item6 {
          text-align: right;
        }
      }
      @include mob {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        left: 0%;
        .item1 {
          min-width: $m200px;
        }
        .item2 {
          min-width: $m234px;
        }
        .item3 {
          min-width: $m358px;
        }
        .item4 {
          min-width: $m200px;
        }
        .item5 {
          min-width: $m305px;
        }
        .item6 {
          min-width: $m350px;
        }
      }
      .item {
        position: relative;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #000000;
        @include mob {
          font-style: normal;
        }
        .position {
          font-size: $d20px;
          line-height: 87%;
          margin-bottom: $h17px;
          @include transition(all 0.3s ease-in-out);
          @include mob {
            font-size: $m20px;
            margin-bottom: $m17px;
          }
        }
        .title {
          font-size: $d56px;
          line-height: 87%;
          color: #000000;
          cursor: pointer;
          @include transition(all 0.3s ease-in-out);
          @include mob {
            font-size: $m45px;
            white-space: nowrap;
          }
          &:hover {
            text-decoration: none;
          }
        }
        .fictionAction {
          position: absolute;
          right: 0;
          top: 0;
          font-size: $d20px;
          line-height: 87%;
          margin-bottom: $h17px;
          white-space: nowrap;
          @include transition(all 0.3s ease-in-out);
          img {
            width: $d14px;
            height: $d14px;
            margin-top: -$d4px;
          }
          @include mob {
            font-size: $m20px;
            margin-bottom: $m17px;
            right: auto;
            left: 0;
            img {
              width: $m14px;
              height: $m14px;
              margin-top: -$m4px;
            }
          }
          opacity: 0;
          pointer-events: none;
        }
        &.item2,
        &.item3 {
          .fictionAction {
            left: 0;
            right: auto;
          }
        }
      }
      @include mob {
        .item + .item {
          margin-left: $m54px;
        }
      }
    }
  }
  .triangle {
    top: -$d16px;
    left: -$d136px;
    background-image: url(../images/triangle.svg);
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: $d273px;
    height: $d240px;
    @include transition(all 0.3s ease-in-out);
    @include mob {
      background-image: url(../images/triangleM.svg);
      top: $m243px;
      left: $m114px;
      width: $m162px;
      height: $m145px;
    }
  }
  .showrealButton_box_of_box {
    top: -$d182px;
    left: -$d272px;
    @include mob {
      top: $m144px;
      left: $m31px;
    }
    .showrealButton_box {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: 0px 0px;
      @include transition(opacity 0.3s ease-in-out);
      @include mob {
        transform: none !important;
      }
      .showrealButton {
        display: block;
        width: $d248px;
        height: $d248px;
        background-image: url(../images/homeCircle.svg);
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        -webkit-animation: rotateBtn 5s linear infinite;
        animation: rotateBtn 5s linear infinite;
        // cursor: pointer;
        border-radius: 100%;
        @include mob {
          width: $m155px;
          height: $m154px;
        }
      }
    }
  }
  .showreal_box {
    position: absolute;
    top: -$d258px;
    left: -$d260px;
    width: $d517px;
    height: $d520px;
    @include mob {
      position: static;
      top: auto;
      left: auto;
      width: $m280px;
      height: $m282px;
      transform: none !important;
    }
    .showreal_bg_big {
      position: absolute;
      top: 0;
      left: 0;
      width: $d517px;
      height: $d520px;
      background-image: url(../images/sb_bg_circle_big.svg);
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      -webkit-animation: rotateBtn 12s linear infinite;
      animation: rotateBtn 12s linear infinite;
      pointer-events: none;
      border-radius: 100%;
      @include mob {
        width: $m280px;
        height: $m282px;
      }
    }
    .showreal_bg_small {
      position: absolute;
      top: $d126px;
      left: $d124px;
      width: $d268px;
      height: $d267px;
      background-image: url(../images/sb_bg_circle_small.svg);
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      -webkit-animation: rotateBtnRevers 10s linear infinite;
      animation: rotateBtnRevers 10s linear infinite;
      @include transition(opacity 0.3s ease-in-out);
      pointer-events: none;
      border-radius: 100%;
      @include mob {
        top: $m68px;
        left: $m67px;
        width: $m146px;
        height: $m145px;
      }
    }
    .showreal_button {
      position: absolute;
      top: $d191px;
      left: $d190px;
      width: $d136px;
      height: $d137px;
      border-radius: 100%;
      @include transition(opacity 0.3s ease-in-out);
      cursor: pointer;
      @include mob {
        top: $m97px;
        left: $m96px;
        width: $m87px;
        height: $m88px;
      }
      .showreal_button_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: $d136px;
        height: $d137px;
        background-image: url(../images/sb_circle.svg);
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        border-radius: 100%;
        -webkit-animation: rotateBtn 8s linear infinite;
        animation: rotateBtn 8s linear infinite;
        pointer-events: none;
        @include mob {
          width: $m87px;
          height: $m88px;
        }
      }
      .showreal_button_play {
        position: absolute;
        top: $d45px;
        left: $d51px;
        width: $d41px;
        height: $d47px;
        background-image: url(../images/sb_play.svg);
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
        @include mob {
          top: $m29px;
          left: $m33px;
          width: $m26px;
          height: $m30px;
        }
      }
    }
  }
  &.hideItems {
    .menu {
      .menuItems {
        .item {
          .position {
            opacity: 0.6;
          }
          .title {
            opacity: 0.6;
          }
        }
      }
    }
    @include desk {
      .showreal_box {
        mix-blend-mode: difference;
        .showreal_bg_big,
        .showreal_bg_small {
          opacity: 0;
        }
        // .showreal_button{
        //   .showreal_button_play{
        //     opacity: 0;
        //   }
        // }
      }
      .showrealButton_box {
        // opacity: 0.78;
        mix-blend-mode: difference;
      }
      .triangle {
        opacity: 0;
      }
    }
    &.hoverItem1 {
      .menu {
        .menuItems {
          .item1 {
            .title {
              opacity: 1;
            }
            @include mob {
              .position {
                opacity: 1;
              }
            }
          }
        }
      }
      @include desk {
        .photoBg1 {
          opacity: 1;
        }
      }
    }
    &.hoverItem2 {
      .menu {
        .menuItems {
          .item2 {
            .title {
              opacity: 1;
            }
            .position {
              opacity: 0;
            }
            .fictionAction {
              opacity: 1;
            }
          }
        }
      }
      @include desk {
        .photoBg2 {
          opacity: 1;
        }
      }
    }
    &.hoverItem3 {
      .menu {
        .menuItems {
          .item3 {
            .title {
              opacity: 1;
            }
            .position {
              opacity: 0;
            }
            .fictionAction {
              opacity: 1;
            }
          }
        }
      }
      @include desk {
        .photoBg3 {
          opacity: 1;
        }
      }
    }
    &.hoverItem4 {
      .menu {
        .menuItems {
          .item4 {
            .title {
              opacity: 1;
            }
            .position {
              opacity: 0;
            }
            .fictionAction {
              opacity: 1;
            }
          }
        }
      }
      @include desk {
        .photoBg4 {
          opacity: 1;
        }
      }
    }
    &.hoverItem5 {
      .menu {
        .menuItems {
          .item5 {
            .title {
              opacity: 1;
            }
            .position {
              opacity: 0;
            }
            .fictionAction {
              opacity: 1;
            }
          }
        }
      }
      @include desk {
        .photoBg5 {
          opacity: 1;
        }
      }
    }
    &.hoverItem6 {
      .menu {
        .menuItems {
          .item6 {
            .title {
              opacity: 1;
            }
            .position {
              opacity: 0;
            }
            .fictionAction {
              opacity: 1;
            }
          }
        }
      }
      @include desk {
        .photoBg6 {
          opacity: 1;
        }
      }
    }
    &.hoverItem7 {
      @include desk {
        .photoBg7 {
          opacity: 1;
        }
      }
    }
  }
  .bigRedCircle {
    position: fixed;
    z-index: 50;
    background: #ff3d22;
    width: $d100px;
    height: $d100px;
    left: -$d369px;
    top: -$d500px;
    @include transition(all 0.9s ease-in-out);
    border-radius: 100%;

    // width: $d1498px;
    // height: $d1498px;
    @include mob {
      width: $m650px;
      height: $m650px;
      left: -$m623px;
      top: -$m541px;
    }
  }
  .showrealBox {
    pointer-events: none;
    @include desk {
      height: 100vh;
      width: 100%;
      overflow-y: hidden;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &.showrealOpen {
    .bigRedCircle {
      width: $d2100px;
      height: $d2100px;
      @include mob {
        width: $m1430px;
        height: $m1430px;
      }
    }
    .showrealBox {
      pointer-events: visible;
      @include desk {
        overflow-y: auto;
      }
    }
    .showrealBox .mobB {
      .mailButton {
        border: 2px solid #ffffff;
      }
      opacity: 1;
      pointer-events: visible;
      -webkit-transition-delay: 0.9s;
      -moz-transition-delay: 0.9s;
      -o-transition-delay: 0.9s;
      transition-delay: 0.9s;
    }
    .showreal {
      opacity: 1;
      display: block;
      pointer-events: visible;
      -webkit-transition-delay: 0.9s;
      -moz-transition-delay: 0.9s;
      -o-transition-delay: 0.9s;
      transition-delay: 0.9s;
      @include desk {
        max-height: $d644px;
      }
    }
  }
  @include mob {
    .showrealBox {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      .mobB {
        @include transition(all 0.3s ease-in-out);
        opacity: 0;
        pointer-events: none;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $m80px $m40px $m36px;
        z-index: 100;
      }
    }
  }
  @include desk {
    .showrealBox .mobB {
      display: none;
    }
  }
  .showreal {
    z-index: 51;
    @include desk {
      position: absolute;
      top: $d117px;
      right: $d136px;
      left: $d136px;
    }
    @include mob {
      position: relative;
      width: $m341px;
      height: auto;
    }
    @include transition(all 0.3s ease-in-out);
    pointer-events: none;
    opacity: 0;
    .videoBox {
      width: 100%;
      padding: 56.25% 0 0 0;
      @include desk {
        position: absolute;
        top: 0;
        left: 0;
        // height: 100%;
      }
      overflow: hidden;
      // video{
      //   width: 100%;
      // }
    }
    // .screensaver{
    //   z-index: 80;
    //   position: absolute; top: 0; left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: url(../images/showrealBg.jpg);
    //   background-position: center center;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    // }
    // .play_box{
    //   width: 100%;
    //   height: 100%;
    //   z-index: 90;
    //   position: absolute; top: 0; left: 0;
    //   cursor: pointer;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   .play{
    //     @include desk{
    //       display: flex;
    //       gap: $d14px;
    //       align-items: center;
    //       justify-content: center;
    //       flex-direction: row;
    //       height: $d30px;
    //       top: $d23px; right: $d23px;
    //       cursor: pointer;
    //       .cText{
    //         font-style: normal;
    //         font-weight: 400;
    //         font-size: $d30px;
    //         line-height: $d30px;
    //         padding-top: $d6px;
    //         text-transform: uppercase;
    //         color: #FFFFFF;
    //       }
    //       .cIcon{
    //         width: $d43px;
    //         height: $d43px;
    //         background-image: url(../images/playIcon.svg);
    //         background-position: center center;
    //         background-size: 100%;
    //         background-repeat: no-repeat;
    //       }
    //     }
    //     @include mob{
    //       width: $m45px;
    //       height: $m52px;
    //       background-image: url(../images/playM.svg);
    //       background-position: center center;
    //       background-size: 100%;
    //       background-repeat: no-repeat;
    //       .cText,.cIcon{display: none;}
    //     }
    //   }
    // }
    &.play {
      .play_box {
        display: none;
      }
    }
    &.hideScreensaver {
      .screensaver {
        display: none;
      }
    }
    .close {
      position: absolute;
      z-index: 100;
      display: flex;
      gap: $d20px;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: $d30px;
      top: $d23px;
      right: $d23px;
      cursor: pointer;
      @include mob {
        gap: $m29px;
        height: $m40px;
        top: -$m50px;
        left: $m91px;
        right: auto;
      }
      .cText {
        font-style: normal;
        font-weight: 400;
        font-size: $d30px;
        line-height: $d30px;
        padding-top: $d6px;
        text-transform: uppercase;
        color: #ffffff;
        @include mob {
          font-size: $m30px;
          line-height: $m30px;
          padding-top: $m6px;
        }
      }
      .cIcon {
        width: $d31px;
        height: $d29px;
        background-image: url(../images/close.svg);
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        @include mob {
          width: $m31px;
          height: $m29px;
        }
      }
    }
  }
  @include mob {
    .mobB {
      width: 100%;
      height: $m116px;
      padding-top: $m33px;
      position: relative;
      z-index: 10;
    }
  }
}

.worksPage {
  padding-top: $d171px;
  padding-bottom: $d128px;
  @include mob {
    padding-top: $m111px;
    padding-bottom: $m70px;
  }
  // background-image: url(../images/d2.png);
  // background-position: -7px 34px;
  // background-size: 96.5%;
  // background-repeat: no-repeat;
}

section.projects {
  .container > .title {
    font-style: normal;
    font-weight: 400;
    font-size: $d56px;
    line-height: $d67px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: $d11px;
    @include mob {
      font-size: $m56px;
      line-height: $m67px;
      margin-bottom: $m21px;
      text-align: center;
    }
  }
  .tags {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: $d1368px;
    @include mob {
      max-width: 100%;
      padding: 0 $m17px;
    }
    margin-bottom: $d116px;
    @include mob {
      margin-bottom: $m44px;
      overflow-y: scroll;
      height: $m49px;
    }
    & > .tagsList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      gap: 0;
      @include mob {
        flex-wrap: nowrap;
      }
      & > li {
        display: block;
        a {
          display: flex;
          height: $d49px;
          border: 0.10416666667vw solid #000000;
          border-radius: $d100px;
          padding: 0 $d50px;
          white-space: nowrap;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: $d16px;
          line-height: $d49px;
          color: #000000;
          cursor: pointer;
          text-decoration: none;
          text-transform: uppercase;
          &.selected {
            color: #ffffff;
            background: #000000;
          }
          @include mob {
            height: $m41px;
            border-radius: $m50px;
            padding: 0 $m30px;
            font-size: $m15px;
            line-height: $m21px;
            border: 2px solid #000000;
          }
        }
      }
    }
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $d78px;
    @include mob {
      grid-template-columns: 1fr;
      gap: $m13px;
    }
    .card {
      display: block;
      cursor: pointer;
      width: $d384px;
      height: $d224px;
      position: relative;
      @include transition(all 0.2s ease-in);
      @include mob {
        height: $m195px;
        width: 100%;
      }
      .box {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        .title {
          position: absolute;
          left: $d21px;
          top: $d20px;
          right: $d70px;
          font-style: normal;
          font-weight: 600;
          font-size: $d20px;
          line-height: $d24px;
          text-transform: uppercase;
          color: #ffffff;
          @include transition(all 0.2s ease-in);
          opacity: 0;
          @include mob {
            opacity: 1;
            left: $m17px;
            top: auto;
            right: $m70px;
            bottom: $m17px;
            font-size: $m20px;
            line-height: $m24px;
            text-align: left;
          }
        }
        @include desk {
          .play {
            position: absolute;
            bottom: $d20px;
            right: $d30px;
            display: flex;
            gap: $d6px;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            .cText {
              font-style: normal;
              font-weight: 400;
              font-size: $d23px;
              line-height: $d24px;
              padding-top: $d6px;
              text-transform: uppercase;
              color: #ffffff;
            }
            .cIcon {
              width: $d38px;
              height: $d38px;
              background-image: url(../images/playIcon.svg);
              background-position: center center;
              background-size: 100%;
              background-repeat: no-repeat;
            }
            @include transition(all 0.2s ease-in);
            opacity: 0;
          }
        }
        @include mob {
          .play {
            display: none;
          }
        }
      }
      &.light {
        .title,
        .play {
          color: #000000;
        }
      }
      @include desk {
        &:hover {
          transform: matrix(1.2, 0, 0, 1.2, 0, 0);
          transform-origin: center;
          .title,
          .play {
            opacity: 1;
          }
        }
        &:nth-child(3n + 3):hover {
          transform-origin: right;
        }
        &:nth-child(3n + 1):hover {
          transform-origin: left;
        }
      }
    }
  }
}
section.work {
  .works {
    display: none;
    @include mob {
      display: block;
      padding: $m139px 0 $m30px;
      text-align: center;
    }
  }
  @include mob {
    .mobB {
      // position: absolute; left: 0; right: 0; bottom: 0;
      padding: $m80px $m40px $m36px;
    }
  }
  .videoBox {
    position: relative;
    @include mob {
      width: $m341px;
      margin: 0 auto;
    }
    video {
      width: 100%;
    }
    .play_box {
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // pointer-events: none;
      .play {
        @include desk {
          display: flex;
          gap: $d14px;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          height: $d30px;
          top: $d23px;
          right: $d23px;
          .cText {
            font-style: normal;
            font-weight: 400;
            font-size: $d30px;
            line-height: $d30px;
            padding-top: $d6px;
            text-transform: uppercase;
            color: #ffffff;
          }
          .cIcon {
            width: $d43px;
            height: $d43px;
            background-image: url(../images/playIcon.svg);
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
          }
        }
        @include mob {
          width: $m45px;
          height: $m52px;
          background-image: url(../images/playM.svg);
          background-position: center center;
          background-size: 100%;
          background-repeat: no-repeat;
          .cText,
          .cIcon {
            display: none;
          }
        }
      }
    }
    &.play {
      .play_box {
        display: none;
      }
    }
  }
  .images {
    @include mob {
      width: $m341px;
      margin: 0 auto;
    }
    img {
      width: 100%;
      & + img {
        margin-top: $m17px;
      }
    }
  }
  .panel {
    height: $d129px;
    padding: $d38px 0 $d42px;
    @include mob {
      height: auto;
      padding: $m12px 0 0;
    }
    &.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 51;
    }
    background-color: #ffffff;
    .raw {
      display: flex;
      width: 100%;
      gap: 0;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      @include mob {
        flex-wrap: wrap;
        display: block;
        & > *:nth-child(1) {
          display: none;
        }
        & > *:nth-child(3) {
          text-align: center;
        }
      }
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: $d40px;
        line-height: $d48px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        flex-grow: 1;
        padding: 0 $d30px;
        @include mob {
          font-size: $m23px;
          line-height: $m28px;
          padding: 0;
          margin-bottom: $m29px;
        }
      }
      @include desk {
        .b2 {
          display: flex;
          flex-wrap: nowrap;
        }
      }
    }
  }
}

section.about {
  padding-top: $d166px;
  padding-bottom: $d128px;
  @include mob {
    padding-top: $m120px;
    padding-bottom: $m34px;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: $d56px;
    line-height: $d67px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: $d11px;
    @include mob {
      font-size: $m56px;
      line-height: $m53px;
      margin-bottom: $m26px;
      text-align: center;
    }
  }
  .raw {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $d100px $d132px;
    @include mob {
      grid-template-columns: 1fr;
      gap: $m40px;
    }
    .article {
      font-family: 'Steppe';
      font-style: normal;
      font-weight: 400;
      font-size: $d18px;
      line-height: $d28px;
      color: #000000;
      @include mob {
        font-size: $m18px;
        line-height: $m28px;
      }
      p {
        margin: 0;
        padding: 0;
        margin-bottom: $d28px;
        @include mob {
          margin-bottom: $m28px;
        }
      }
    }
    @include desk {
      .order3 {
        grid-row-start: 2;
      }
      .a2 {
        padding-top: $d90px;
      }
    }
    .imgGroup {
      position: relative;
      @include desk {
        width: $d579px;
        height: $d341px;
      }
      @include mob {
        margin: 0 auto;
      }
      .titleImg {
        position: relative;
        width: 100%;
        height: 100%;
        border: $d2px solid #000;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        // &:hover{
        //   z-index: 10;
        // }
        &.double1 {
          position: absolute;
          top: 0;
          left: 0;
        }
        @include mob {
          border: 2px solid #000;
        }
      }
      .imgs {
        .img {
          position: absolute;
          border: $d2px solid #000;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          width: $d579px;
          height: $d341px;
          @include desk {
            opacity: 0;
            @include transition(all 0.3s ease-in-out);
            @include transform_rotate(0);
          }
          @include mob {
            border: 2px solid #000;
          }
        }
      }
      &.imgGroup1 {
        @include mob {
          width: $m321px;
          height: $m341px;
          .titleImg {
            width: $m321px;
            height: $m189px;
          }
        }
        @include desk {
          .img1 {
            top: 0;
            right: 0;
          }
          .img2 {
            top: 0;
            left: 0;
          }
          .img3 {
            top: 0;
            left: 0;
          }
        }
      }
      &.imgGroup2 {
        @include mob {
          width: $m318px;
          height: $m394px;
          .titleImg {
            width: $m318px;
            height: $m187px;
          }
        }
        .img {
          width: $d586px;
          height: $d345px;
        }
        @include desk {
          .img1 {
            top: 0;
            right: 0;
          }
          .img2 {
            top: 0;
            left: 0;
          }
          .img3 {
            top: 0;
            right: 0;
          }
        }
      }
      @include desk {
        &:hover {
          .imgs .img {
            opacity: 1;
          }
          &.imgGroup1 {
            .img1 {
              @include transform_rotate(0.18deg);
              top: -$d65px;
              right: -$d48px;
              left: auto;
              width: $d513px;
              height: $d303px;
            }
            .img2 {
              @include transform_rotate(-5.34deg);
              top: $d49px;
              left: $d47px;
              right: auto;
              width: $d529px;
              height: $d312px;
            }
            .img3 {
              @include transform_rotate(2.97deg);
              top: $d179px;
              left: $d61px;
              right: auto;
              width: $d465px;
              height: $d274px;
            }
          }
          &.imgGroup2 {
            .img1 {
              @include transform_rotate(0);
              top: -$d44px;
              right: -$d13px;
              left: auto;
              width: $d477px;
              height: $d282px;
            }
            .img2 {
              @include transform_rotate(-5.22deg);
              top: $d51px;
              left: -$d27px;
              right: auto;
              width: $d503px;
              height: $d296px;
            }
            .img3 {
              @include transform_rotate(2.97deg);
              top: $d121px;
              right: -$d36px;
              left: auto;
              width: $d461px;
              height: $d272px;
            }
          }
        }
      }
      @include mob {
        &.imgGroup1 {
          .img1 {
            @include transform_rotate(3.54deg);
            top: $m51px;
            left: $m36px;
            width: $m284px;
            height: $m168px;
          }
          .img2 {
            @include transform_rotate(-5.34deg);
            top: $m102px;
            left: -$m10px;
            width: $m293px;
            height: $m173px;
          }
          .img3 {
            @include transform_rotate(2.97deg);
            top: $m176px;
            left: $m63px;
            width: $m258px;
            height: $m152px;
          }
        }
        &.imgGroup2 {
          .img1 {
            @include transform_rotate(2.23deg);
            top: $m72px;
            left: $m47px;
            width: $m274px;
            height: $m161px;
          }
          .img2 {
            @include transform_rotate(-5.22deg);
            top: $m155px;
            left: -$m12px;
            width: $m286px;
            height: $m168px;
          }
          .img3 {
            @include transform_rotate(2.33deg);
            top: $m221px;
            left: $m48px;
            width: $m273px;
            height: $m161px;
          }
        }
      }
      .titleImg.double1,
      .imgs .img.double2,
      .imgs .img.double3,
      .imgs .img.double4 {
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        @include transition(all 0.3s ease-in-out);
        @include transition('all .3s ease-in-out, opacity .5s ease-in-out');
      }
      &:hover {
        .titleImg.double1,
        .imgs .img.double2,
        .imgs .img.double3,
        .imgs .img.double4 {
          &.hovered {
            z-index: 16;
            pointer-events: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
.form {
  display: flex;
  flex-flow: row wrap;
  gap: $d30px $d39px;
  @include mob {
    gap: $m24px;
  }
  .col {
    flex-grow: 0.5;
    @include mob {
      width: 100%;
      flex-grow: 1;
    }
  }
  .col:nth-child(3),
  .col:nth-child(4),
  .col:nth-child(5) {
    width: 100%;
    flex-grow: 1;
  }
  input {
    display: block;
    padding: 0;
    color: #000000;
    width: 100%;
    height: $d34px;
    outline: 0;
    background: #ff3d22;
    background: transparent;
    border: 0px;
    border-bottom: $d2px solid #000000;
    font-family: 'Steppe';
    font-style: normal;
    font-weight: 600;
    font-size: $d17px;
    line-height: $d20px;
    text-align: left;
    border-radius: 0px !important;
    &:focus {
    }
    @include mob {
      height: $m34px;
      font-size: $m17px;
      line-height: $m20px;
      border-bottom: 2px solid #000000;
    }
  }
  input::placeholder,
  textarea::placeholder {
    color: #000000;
  }
  textarea {
    display: block;
    padding: 0;
    outline: 0;
    border: 0px;
    width: 100%;
    resize: none;
    background: #ff3d22;
    background: transparent;
    border-bottom: $d2px solid #000000;
    font-family: 'Steppe';
    font-style: normal;
    font-weight: 600;
    font-size: $d17px;
    line-height: $d20px;
    text-align: left;
    border-radius: 0px !important;
    height: $d104px;
    @include mob {
      height: $m135px;
      font-size: $m17px;
      line-height: $m20px;
      border-bottom: 2px solid #000000;
    }
  }
  .button {
    border: 0.10416666667vw solid #ffffff;
    &:hover {
      background: #ffffff;
      color: #000000;
    }
    @include mob {
      border: 2px solid #ffffff;
    }
  }
}
